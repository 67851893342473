import { Button, Text, View, Link } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import React, { useContext, useState, useEffect } from "react";
import { strings } from "../strings";
import { Context } from "../contexts/context";
import PasswordInputs from "../components/passwordInputs";
import BasicLayout from "../components/basicLayout";
import { confirmSignIn, signIn, fetchAuthSession } from "aws-amplify/auth";

I18n.putVocabularies(strings);

const AccountActivation = () => {
	const { user, tempPassword } = useContext(Context);

	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");
	const [isErrorVisible, setIsErrorVisible] = useState(false);
	const [allValid, setAllValid] = useState(false);

	useEffect(() => {
		setIsErrorVisible(error !== "" && error !== undefined);
	}, [error]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError("");
		setIsLoading(true);

		if (!allValid) {
			setIsLoading(false);
			setError(I18n.get("Password does not meet requirements"));
			setIsErrorVisible(true);
			return;
		}

		signIn({
			username: user.email,
			password: tempPassword,
			options: {
				authFlowType: "USER_SRP_AUTH",
			},
		})
			.then(async (e) => {
				confirmSignIn({
					challengeResponse: newPassword,
				})
					.then(async (e) => {
						const session = await fetchAuthSession();

						const queryParams = new URLSearchParams(
							window.location.search
						);

						const redirect_uri = queryParams.get("redirect_uri");
						const id_token = session?.tokens?.idToken.toString();
						const access_token =
							session?.tokens?.accessToken.toString();

						try {
							const myHeaders = new Headers();
							myHeaders.append(
								"Authorization",
								"Bearer " + access_token
							);
							const res = await fetch(
								"https://44gsekqdjf.execute-api.eu-central-1.amazonaws.com/Prod/api/v1/users/confirm-email",
								{
									method: "POST",
									headers: myHeaders,
								}
							);
						} catch (error) {
							console.log(error);
						}

						if (redirect_uri && id_token) {
							window.location.reload();
						} else {
							window.location.href = "/accountActivationSuccess";
						}
						setIsLoading(false);
					})
					.catch((e) => {
						console.log(e);
						setError(I18n.get(e.message));
						setIsErrorVisible(true);
						setIsLoading(false);
					});
			})
			.catch((e) => {
				console.log(e);
				setError(I18n.get("Temporary password not correct."));
				setIsErrorVisible(true);
				setIsLoading(false);
			});
	};

	return (
		<BasicLayout>
			<form
				onSubmit={handleSubmit}
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
				}}
			>
				<View textAlign="center">
					<div
						style={{
							width: "fit-content",
							marginInline: "auto",
							textAlign: "center",
							padding: "8px",
							borderRadius: "12px",
							backgroundColor: "#FFFCF5",
							border: "1px solid #FEC84B",
							color: "#B54708",
							fontWeight: "600",
							display: "flex",
							alignItems: "center",
							gap: "8px",
							lineHeight: "none",
							// marginTop: "8px",
						}}
					>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
								stroke="#DC6803"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>

						{I18n.get("Account activation alert")}
					</div>
					<Text
						as="h2"
						fontSize="30px"
						fontWeight="600"
						lineHeight="30px"
						marginTop="32px"
						marginBottom="12px"
					>
						{I18n.get("Account activation password title")}
					</Text>
					<Text
						as="p"
						fontSize="16px"
						fontWeight="400"
						lineHeight="24px"
						marginBottom="32px"
						color="#4A5C7F"
					>
						{I18n.get("Account activation password description")}
					</Text>
				</View>
				<PasswordInputs
					newPassword={newPassword}
					setNewPassword={setNewPassword}
					confirmNewPassword={confirmNewPassword}
					setConfirmNewPassword={setConfirmNewPassword}
					allValid={allValid}
					setAllValid={setAllValid}
				/>
				{isErrorVisible ? (
					<div
						className="amplify-flex amplify-alert amplify-alert--error"
						role="alert"
						style={{
							marginBottom: "24px",
						}}
					>
						<span className="amplify-alert__icon">
							<span className="amplify-icon" aria-hidden="true">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
										fill="currentColor"
									></path>
								</svg>
							</span>
						</span>
						<div
							style={{
								flex: "1 1 0%",
							}}
						>
							<div className="amplify-alert__body">
								<span>{I18n.get(error)}</span>
								{/* {Object.keys(validationErrors)?.map(
											(err) => {
												return (
													<span>
														{I18n.get(
															validationErrors[err]
														)}
													</span>
												);
											}
										)} */}
							</div>
						</div>
						<button
							className="amplify-button amplify-field-group__control amplify-button--link amplify-alert__dismiss"
							type="button"
							onClick={() => setIsErrorVisible(false)}
						>
							<span className="amplify-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
										fill="currentColor"
									></path>
								</svg>
							</span>
						</button>
					</div>
				) : null}
				<Button
					className="amplify-button amplify-field-group__control amplify-button--primary"
					type="submit"
					isLoading={isLoading}
				>
					{I18n.get("Account activation password submit")}
				</Button>
				<Link
					href="/"
					className="amplify-button amplify-field-group__control amplify-button--link amplify-button--small"
					style={{
						display: "flex",
						marginInline: "auto",
						alignItems: "center",
						gap: "8px",
						marginTop: "32px",
					}}
				>
					<svg
						width="21"
						height="20"
						viewBox="0 0 21 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M16.3337 10H4.66699M4.66699 10L10.5003 15.8333M4.66699 10L10.5003 4.16666"
							stroke="#2B64F6"
							strokeWidth="1.66667"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<span>{I18n.get("Back to login")}</span>
				</Link>
			</form>
		</BasicLayout>
	);
};

export default AccountActivation;
